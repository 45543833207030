.info-panel-container {
  display: flex;
  justify-content: center;
  pointer-events: none !important;
}

.info-panel {
  position: relative;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302),0 2px 6px 2px rgba(60,64,67,0.149);
  padding: 20px;
  pointer-events: all;
  cursor: default;

  &.default {
    width: 600px;
  }

  &.small-screen {
    width: 100%;
  }

  &.mobile-device {
    .info-scroll {
      height: calc(100% - 32px - 10px - 40px) !important;
    }
  }

  .param {
    display: flex;
    align-items: flex-start;
    min-height: 20px;
    padding: 4px 0 4px 0;
    box-sizing: border-box;
  }

  $left-width: 30%;
  $label-margin: 10px;

  .label {
    display: flex;
    align-items: center;
    margin: 0 $label-margin 0 0;
    width: $left-width;
    min-width: 140px;
  }

  .value {
    display: flex;
    align-items: center;
    width: calc(100% - #{$left-width});
  }

  .info-buttons-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  .delete-button {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: $left-width;

    .delete-icon {
      margin-right: 10px;

      path {
        fill: gray;
      }
    }
  }
}

.info-scroll {
  height: calc(100% - 32px - 10px) !important;
}

.info-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .icon {
    margin: 0 10px 0 0;
  }

  .name {
    @include text-truncate();
    font-size: 20px;
    max-width: calc(100% - 32px /*icon width*/ - 10px /*margin*/);
  }
}

@import 'node_modules/react-sliding-side-panel/lib';