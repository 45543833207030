.add-button {
  position: absolute !important;
  top: 10px;
  right: 128px;
  background-color: white;
  border-radius: 24px;

  z-index: 20000;
}

.add-panel-container {
  padding: 0 0 0 10px;
  box-sizing: border-box;
}

.add-panel {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  height: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302),0 2px 6px 2px rgba(60,64,67,0.149);

  cursor: default;

  &.mobile-device {
    .add-panel-scroll {
      height: calc(100% - 40px - 40px) !important;
    }

    .preferences-scroll {
      height: calc(100% - 40px - 40px) !important;
    }
  }
}

.add-panel-scroll {
  height: calc(100% - 40px) !important;
}

.add-panel-title {
  font-size: 30px;
  width: 100%;
  margin: 0 0 5px 0;
  box-sizing: border-box;
}

.add-panel-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0 0 0;

  .add-panel-toggle {
    width: 60px;
    flex-shrink: 0;
  }

  .add-panel-label {
    width: 100%;
    min-width: 0;
  }
}