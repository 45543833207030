@import "src/style/mixin";

@import "src/map/info/info-panel";
@import "src/map/add/add";
@import "src/map/refresh/refresh";
@import "src/map/preferences/preferences";

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map {
    width: 100%;
    height: 100%;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: blue; /*var(--main-blue);*/
}

.pin-icon {
    font-size: 4rem;
}

.pin-text {
    font-size: 1.3em;
}

.text-label {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 40px;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}