.menu-button {
  position: absolute !important;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 4px;

  z-index: 20000;
}

.preferences-panel-container {
  padding: 0 0 0 10px;
  box-sizing: border-box;
}

.preferences {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  height: 100%;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302),0 2px 6px 2px rgba(60,64,67,0.149);

  cursor: default;

  &.mobile-device {
    .preferences-scroll {
      height: calc(100% - 40px - 40px - 40px) !important;
    }
  }

  .search-input {
    margin: 0 0 10px 0;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
}

.preferences-scroll {
  height: calc(100% - 40px) !important;
}

.preferences-title {
  font-size: 30px;
  width: 100%;
  margin: 0 0 5px 0;
  box-sizing: border-box;
}

.preference-row {
  display: flex;
  align-items: center;
  width: 100%;

  &.group-header {
    padding-left: 8px;
  }

  .arrow-icon {
    display: flex;
    align-items: center;

    path {fill: #6a6a6a;}
  }
}

.preference-toggle {
  width: 60px;
}

.preference-label {
  width: calc(100% - 60px);
}

.preference-icon {
  margin: 0 10px 0 0;
}

.preference-group {
  margin: 20px 0 20px 0;
  overflow-x: hidden;

  .group-title {
    font-size: 20px;
    width: 100%;
    margin: 0 0 30px 0;
  }
}