.refresh-button {
  position: absolute !important;
  top: 10px;
  right: 70px;
  background-color: white;
  border-radius: 24px;

  z-index: 20000;

  .refresh-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 2px;
  }
}