@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-truncate-max-width($maxWidth: 300) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: $maxWidth + 0px;
}